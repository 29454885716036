import React from 'react';
import './App.css';
import introJson from "./data/intro.json"
import publicationJson from './data/publications.json';
import contactInfoJson from "./data/contact_info.json";

var shortid = require('shortid');

function Intro(props) {
  const introList = introJson.lines.map(tLine =>
    <div key={shortid.generate()}><p>{tLine}</p> </div>);
  return (
    <div className="Intro">
      <h1>{introJson.title}</h1>
      
      {introList}
    </div>
  );
}

function ContactInfoEntryEmail(props) {
   return <p className="Contact-Info-Email">{props.addr + "@" + props.host}</p>;
}

function ContactInfoEntryLink(props) {
  return <p className="Contact-Info-Link">{<a href={props.addr} target="_blank">{props.text}</a>}</p>
}

function ContactInfoEntry(props) {
  switch (props.entry.type) {
      case "EMAIL":
        return <ContactInfoEntryEmail {...props.entry} />
      case "LINK":
        return <ContactInfoEntryLink {...props.entry} />
      default:
        return null;
  }
}

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.state = {isShowingInfo: false};
  }

  handleShowClick() {
    this.setState({isShowingInfo: true});
  }

  render() {
    const isShowingInfo = this.state.isShowingInfo;
 
    if (isShowingInfo) {
      return (
        <div className="Contact-Info">
          <h2>Contact Info</h2>
          <div>{contactInfoJson.map((contactInfoEntry) => <ContactInfoEntry entry={contactInfoEntry} key={shortid.generate()}/>)}</div>
        </div>
      );
    }

    return (
      <div className="Contact-Info">
        <h2>Contact Info</h2>
        <p onClick={this.handleShowClick}>Click this text to show</p>
     </div>
    );
  }
}

function Publication(props) {
  return (
    <p className="Publication">
      {props.publicationText}&nbsp;
      {props.pdfUrl != null && <a href={props.pdfUrl} target="_blank">pdf</a>}&nbsp;
      {props.bibtexUrl != null && <a href={props.bibtexUrl} target="_blank">bibtex</a>}
    </p>
    );
}

function PublicationList(props) {
  const publicationList = props.publications.map((publication) =>
    <div key={shortid.generate()}><Publication publicationText={publication.text} pdfUrl={publication.pdf} bibtexUrl={publication.bibtex}/></div>);
  return (
    <div className="Publication-List">
      <h2>Publications</h2>
      {publicationList}
    </div>
 
  );
}


function App() {
  return (
    <div className="App">
      <Intro />
      <ContactInfo />
      <PublicationList publications={publicationJson}/>
    </div>
  );
}

export default App;
